@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-extraSmall {
  font-size: 0.7em !important;
}
.text-small {
  font-size: 0.8em !important;
}
.text-medium {
  font-size: 0.9em !important;
}
.text-intermediate {
  font-size: 1em !important;
}
.text-large {
  font-size: 1.1em !important;
}
.text-larger {
  font-size: 1.2em !important;
}
.text-largest {
  font-size: 1.3em !important;
}
.text-doubleXL {
  font-size: 1.5em !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.font-primary {
  font-family: "Poppins", sans-serif !important;
}
